export default {
  cs: {
    chat: {
      detail: {
        title: 'Posigneo chatbot',
      },
      grid: {
        title: 'Chat',
        actions: {
          newConversation: {
            title: 'Nový chat',
          },
        },
      },
      messages: {
        welcome: 'Jak Vám můžeme pomoci?',
        none: 'Žádné zprávy',
        error: 'Chyba při komunikaci. Zkuste to prosím znovu.',
      },
      actions: {
        send: 'Odeslat',
        continue: 'Pokračovat',
      },
      status: {
        connecting: 'Připojování...',
      },
      form: {
        placeholder: 'Napište zprávu...',
      },
      labels: {
        messages: 'Zprávy',
      },
    },
  },
  sk: {
    chat: {
      detail: {
        title: 'Posigneo chatbot',
      },
      grid: {
        title: 'Chat',
        actions: {
          newConversation: {
            title: 'Nová správa',
          },
        },
      },
      messages: {
        welcome: 'Ako Vám môžeme pomôcť?',
        none: 'Žiadne správy',
        error: 'Chyba pri komunikácii. Skúste to prosím znova.',
      },
      actions: {
        send: 'Odoslať',
        continue: 'Pokračovať',
      },
      status: {
        connecting: 'Pripájanie...',
      },
      form: {
        placeholder: 'Napíšte správu...',
      },
      labels: {
        messages: 'Správy',
      },
    },
  },
  en: {
    chat: {
      detail: {
        title: 'Posigneo chatbot',
      },
      grid: {
        title: 'Chat',
        actions: {
          newConversation: {
            title: 'New message',
          },
        },
      },
      messages: {
        welcome: 'How can we help you?',
        none: 'No messages',
        error: 'Communication error. Please try again.',
      },
      actions: {
        send: 'Send',
        continue: 'Continue',
      },
      status: {
        connecting: 'Connecting...',
      },
      form: {
        placeholder: 'Type a message...',
      },
      labels: {
        messages: 'Messages',
      },
    },
  },
  de: {
    chat: {
      detail: {
        title: 'Posigneo chatbot',
      },
      grid: {
        title: 'Chat',
        actions: {
          newConversation: {
            title: 'Neue Nachricht',
          },
        },
      },
      messages: {
        welcome: 'Wie können wir Ihnen helfen?',
        none: 'Keine Nachrichten',
        error: 'Kommunikationsfehler. Bitte versuchen Sie es erneut.',
      },
      actions: {
        send: 'Senden',
        continue: 'Fortsetzen',
      },
      status: {
        connecting: 'Verbindung wird hergestellt...',
      },
      form: {
        placeholder: 'Nachricht eingeben...',
      },
      labels: {
        messages: 'Nachrichten',
      },
    },
  },
};

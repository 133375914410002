import NumberType from '@api/posMiddlewareCore/types/NumberType';
import StringType from '@api/posMiddlewareCore/types/StringType';
import {
  Entity as EntityBase,
  RealTypesOf,
  SerializedTypesOf,
} from '@designeo/apibundle-js';

export const EnumFieldFields = {
  id: {
    type: new NumberType(),
  },
  name: {
    type: new StringType(),
  },
};

export interface IEnumFieldBaseData {
  id: NumberType,
  name: StringType,
}

export default class EnumFieldBase<
  OverrideCtorData extends Record<string, any> = IEnumFieldBaseData,
  OverrideData extends Record<string, any> = IEnumFieldBaseData
> extends EntityBase<
  SerializedTypesOf<OverrideCtorData>,
  RealTypesOf<OverrideData>
  > {
  readonly $fields = EnumFieldFields;
  static readonly $fields = EnumFieldFields;
  readonly $validations = null;
  static readonly $validations = null;
  get id() {
    return this.$get('id');
  }
  set id(value) {
    this.$set('id', value);
  }
  get name() {
    return this.$get('name');
  }
  set name(value) {
    this.$set('name', value);
  }
}

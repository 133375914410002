import VoucherEmissionSmallDto from '@api/posMiddlewareCore/entity/VoucherEmissionSmallDto';
import VoucherState from '@api/posMiddlewareCore/entity/VoucherState';
import DateTimeType from '@api/posMiddlewareCore/types/DateTimeType';
import EntityType from '@api/posMiddlewareCore/types/EntityType';
import NumberType from '@api/posMiddlewareCore/types/NumberType';
import StringType from '@api/posMiddlewareCore/types/StringType';
import {
  Entity as EntityBase,
  RealTypesOf,
  SerializedTypesOf,
} from '@designeo/apibundle-js';

export const VoucherPreviewDtoFields = {
  id: {
    type: new StringType(),
  },
  voucherNumber: {
    type: new StringType(),
  },
  validFrom: {
    type: new DateTimeType(),
  },
  validTill: {
    type: new DateTimeType(),
  },
  state: {
    type: new EntityType(() => VoucherState),
  },
  usedAt: {
    type: new DateTimeType(),
  },
  usedPlace: {
    type: new StringType(),
  },
  emission: {
    type: new EntityType(() => VoucherEmissionSmallDto),
  },
  maxUsageCount: {
    type: new NumberType(),
  },
  usageCount: {
    type: new NumberType(),
  },
};

export interface IVoucherPreviewDtoBaseData {
  id: StringType,
  voucherNumber: StringType,
  validFrom: DateTimeType,
  validTill: DateTimeType,
  state: EntityType<VoucherState>,
  usedAt: DateTimeType,
  usedPlace: StringType,
  emission: EntityType<VoucherEmissionSmallDto>,
  maxUsageCount: NumberType,
  usageCount: NumberType,
}

export default class VoucherPreviewDtoBase<
  OverrideCtorData extends Record<string, any> = IVoucherPreviewDtoBaseData,
  OverrideData extends Record<string, any> = IVoucherPreviewDtoBaseData
> extends EntityBase<
  SerializedTypesOf<OverrideCtorData>,
  RealTypesOf<OverrideData>
  > {
  readonly $fields = VoucherPreviewDtoFields;
  static readonly $fields = VoucherPreviewDtoFields;
  readonly $validations = null;
  static readonly $validations = null;
  get id() {
    return this.$get('id');
  }
  set id(value) {
    this.$set('id', value);
  }
  get voucherNumber() {
    return this.$get('voucherNumber');
  }
  set voucherNumber(value) {
    this.$set('voucherNumber', value);
  }
  get validFrom() {
    return this.$get('validFrom');
  }
  set validFrom(value) {
    this.$set('validFrom', value);
  }
  get validTill() {
    return this.$get('validTill');
  }
  set validTill(value) {
    this.$set('validTill', value);
  }
  get state() {
    return this.$get('state');
  }
  set state(value) {
    this.$set('state', value);
  }
  get usedAt() {
    return this.$get('usedAt');
  }
  set usedAt(value) {
    this.$set('usedAt', value);
  }
  get usedPlace() {
    return this.$get('usedPlace');
  }
  set usedPlace(value) {
    this.$set('usedPlace', value);
  }
  get emission() {
    return this.$get('emission');
  }
  set emission(value) {
    this.$set('emission', value);
  }
  get maxUsageCount() {
    return this.$get('maxUsageCount');
  }
  set maxUsageCount(value) {
    this.$set('maxUsageCount', value);
  }
  get usageCount() {
    return this.$get('usageCount');
  }
  set usageCount(value) {
    this.$set('usageCount', value);
  }
}

import StringType from '@api/posMiddlewareCore/types/StringType';
import {
  Entity as EntityBase,
  RealTypesOf,
  SerializedTypesOf,
} from '@designeo/apibundle-js';

export const ILinkObjectFields = {
  operationId: {
    type: new StringType(),
  },
};

export interface IILinkObjectBaseData {
  operationId: StringType,
}

export default class ILinkObjectBase<
  OverrideCtorData extends Record<string, any> = IILinkObjectBaseData,
  OverrideData extends Record<string, any> = IILinkObjectBaseData
> extends EntityBase<
  SerializedTypesOf<OverrideCtorData>,
  RealTypesOf<OverrideData>
  > {
  readonly $fields = ILinkObjectFields;
  static readonly $fields = ILinkObjectFields;
  readonly $validations = null;
  static readonly $validations = null;
  get operationId() {
    return this.$get('operationId');
  }
  set operationId(value) {
    this.$set('operationId', value);
  }
}

import {App} from 'vue';
import routes from './routes';
import translations from './translations';

/**
 * Defines what this module needs
 */
export type ChatRequiredContext = import('@ui/plugins/modules').ModuleSystem & {
  app: App<any>,
}

/**
 * Define what this module provides thru context
 */
export type ChatContext = ChatRequiredContext & {
}

export async function registerChatModule(ctx: ChatRequiredContext) {
  await ctx.registerRoutes(routes);

  await ctx.registerTranslations(translations);

  return {};
}

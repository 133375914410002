/* eslint-disable max-len */
import {kebabCase, map} from 'lodash-es';
import {
  computed,
  defineComponent,
  h,
  PropType,
} from 'vue';

const sanitizeKey = <S extends string>(str: S) => kebabCase(str).replace(/^ph-/, '') as S;

export const phIcons = {
  [sanitizeKey('PhArchive')]: () => import('phosphor-vue/dist/esm/components/PhArchive.vue.js'),
  [sanitizeKey('PhArrowBendDownRight')]: () => import('phosphor-vue/dist/esm/components/PhArrowBendDownRight.vue.js'),
  [sanitizeKey('PhArrowDown')]: () => import('phosphor-vue/dist/esm/components/PhArrowDown.vue.js'),
  [sanitizeKey('PhArrowLeft')]: () => import('phosphor-vue/dist/esm/components/PhArrowLeft.vue.js'),
  [sanitizeKey('PhArrowsCounterClockwise')]: () => import('phosphor-vue/dist/esm/components/PhArrowsCounterClockwise.vue.js'),
  [sanitizeKey('PhArrowsOut')]: () => import('phosphor-vue/dist/esm/components/PhArrowsOut.vue.js'),
  [sanitizeKey('PhArrowSquareIn')]: () => import('phosphor-vue/dist/esm/components/PhArrowSquareIn.vue.js'),
  [sanitizeKey('PhArrowSquareOut')]: () => import('phosphor-vue/dist/esm/components/PhArrowSquareOut.vue.js'),
  [sanitizeKey('PhArrowsVertical')]: () => import('phosphor-vue/dist/esm/components/PhArrowsVertical.vue.js'),
  [sanitizeKey('PhArrowUp')]: () => import('phosphor-vue/dist/esm/components/PhArrowUp.vue.js'),
  [sanitizeKey('PhBuildings')]: () => import('phosphor-vue/dist/esm/components/PhBuildings.vue.js'),
  [sanitizeKey('PhCalendar')]: () => import('phosphor-vue/dist/esm/components/PhCalendar.vue.js'),
  [sanitizeKey('PhCalendarBlank')]: () => import('phosphor-vue/dist/esm/components/PhCalendarBlank.vue.js'),
  [sanitizeKey('PhCards')]: () => import('phosphor-vue/dist/esm/components/PhCards.vue.js'),
  [sanitizeKey('PhCaretDoubleLeft')]: () => import('phosphor-vue/dist/esm/components/PhCaretDoubleLeft.vue.js'),
  [sanitizeKey('PhCaretDoubleRight')]: () => import('phosphor-vue/dist/esm/components/PhCaretDoubleRight.vue.js'),
  [sanitizeKey('PhChatCircle')]: () => import('phosphor-vue/dist/esm/components/PhChatCircle.vue.js'),
  [sanitizeKey('PhCaretDown')]: () => import('phosphor-vue/dist/esm/components/PhCaretDown.vue.js'),
  [sanitizeKey('PhCaretLeft')]: () => import('phosphor-vue/dist/esm/components/PhCaretLeft.vue.js'),
  [sanitizeKey('PhCaretRight')]: () => import('phosphor-vue/dist/esm/components/PhCaretRight.vue.js'),
  [sanitizeKey('PhChartLineUp')]: () => import('phosphor-vue/dist/esm/components/PhChartLineUp.vue.js'),
  [sanitizeKey('PhCheck')]: () => import('phosphor-vue/dist/esm/components/PhCheck.vue.js'),
  [sanitizeKey('PhCheckCircle')]: () => import('phosphor-vue/dist/esm/components/PhCheckCircle.vue.js'),
  [sanitizeKey('PhChecks')]: () => import('phosphor-vue/dist/esm/components/PhChecks.vue.js'),
  [sanitizeKey('PhCircleDashed')]: () => import('phosphor-vue/dist/esm/components/PhCircleDashed.vue.js'),
  [sanitizeKey('PhClipboardText')]: () => import('phosphor-vue/dist/esm/components/PhClipboardText.vue.js'),
  [sanitizeKey('PhClock')]: () => import('phosphor-vue/dist/esm/components/PhClock.vue.js'),
  [sanitizeKey('PhCloudSlash')]: () => import('phosphor-vue/dist/esm/components/PhCloudSlash.vue.js'),
  [sanitizeKey('PhCode')]: () => import('phosphor-vue/dist/esm/components/PhCode.vue.js'),
  [sanitizeKey('PhDesktop')]: () => import('phosphor-vue/dist/esm/components/PhDesktop.vue.js'),
  [sanitizeKey('PhDotsNine')]: () => import('phosphor-vue/dist/esm/components/PhDotsNine.vue.js'),
  [sanitizeKey('PhDotsSixVertical')]: () => import('phosphor-vue/dist/esm/components/PhDotsSixVertical.vue.js'),
  [sanitizeKey('PhDotsThree')]: () => import('phosphor-vue/dist/esm/components/PhDotsThree.vue.js'),
  [sanitizeKey('PhDotsThreeCircle')]: () => import('phosphor-vue/dist/esm/components/PhDotsThreeCircle.vue.js'),
  [sanitizeKey('PhDotsThreeOutlineVertical')]: () => import('phosphor-vue/dist/esm/components/PhDotsThreeOutlineVertical.vue.js'),
  [sanitizeKey('PhDotsThreeVertical')]: () => import('phosphor-vue/dist/esm/components/PhDotsThreeVertical.vue.js'),
  [sanitizeKey('PhDownloadSimple')]: () => import('phosphor-vue/dist/esm/components/PhDownloadSimple.vue.js'),
  [sanitizeKey('PhEye')]: () => import('phosphor-vue/dist/esm/components/PhEye.vue.js'),
  [sanitizeKey('PhEyeSlash')]: () => import('phosphor-vue/dist/esm/components/PhEyeSlash.vue.js'),
  [sanitizeKey('PhFile')]: () => import('phosphor-vue/dist/esm/components/PhFile.vue.js'),
  [sanitizeKey('PhFileArrowDown')]: () => import('phosphor-vue/dist/esm/components/PhFileArrowDown.vue.js'),
  [sanitizeKey('PhFileArrowUp')]: () => import('phosphor-vue/dist/esm/components/PhFileArrowUp.vue.js'),
  [sanitizeKey('PhFileSearch')]: () => import('phosphor-vue/dist/esm/components/PhFileSearch.vue.js'),
  [sanitizeKey('PhFloppyDisk')]: () => import('phosphor-vue/dist/esm/components/PhFloppyDisk.vue.js'),
  [sanitizeKey('PhFunnel')]: () => import('phosphor-vue/dist/esm/components/PhFunnel.vue.js'),
  [sanitizeKey('PhFunnelSimple')]: () => import('phosphor-vue/dist/esm/components/PhFunnelSimple.vue.js'),
  [sanitizeKey('PhGearSix')]: () => import('phosphor-vue/dist/esm/components/PhGearSix.vue.js'),
  [sanitizeKey('PhGenderFemale')]: () => import('phosphor-vue/dist/esm/components/PhGenderFemale.vue.js'),
  [sanitizeKey('PhGenderIntersex')]: () => import('phosphor-vue/dist/esm/components/PhGenderIntersex.vue.js'),
  [sanitizeKey('PhGenderMale')]: () => import('phosphor-vue/dist/esm/components/PhGenderMale.vue.js'),
  [sanitizeKey('PhGraph')]: () => import('phosphor-vue/dist/esm/components/PhGraph.vue.js'),
  [sanitizeKey('PhHourglass')]: () => import('phosphor-vue/dist/esm/components/PhHourglass.vue.js'),
  [sanitizeKey('PhHouse')]: () => import('phosphor-vue/dist/esm/components/PhHouse.vue.js'),
  [sanitizeKey('PhIdentificationCard')]: () => import('phosphor-vue/dist/esm/components/PhIdentificationCard.vue.js'),
  [sanitizeKey('PhInfo')]: () => import('phosphor-vue/dist/esm/components/PhInfo.vue.js'),
  [sanitizeKey('PhList')]: () => import('phosphor-vue/dist/esm/components/PhList.vue.js'),
  [sanitizeKey('PhListPlus')]: () => import('phosphor-vue/dist/esm/components/PhListPlus.vue.js'),
  [sanitizeKey('PhMagnifyingGlass')]: () => import('phosphor-vue/dist/esm/components/PhMagnifyingGlass.vue.js'),
  [sanitizeKey('PhMegaphone')]: () => import('phosphor-vue/dist/esm/components/PhMegaphone.vue.js'),
  [sanitizeKey('PhMinusCircle')]: () => import('phosphor-vue/dist/esm/components/PhMinusCircle.vue.js'),
  [sanitizeKey('PhNote')]: () => import('phosphor-vue/dist/esm/components/PhNote.vue.js'),
  [sanitizeKey('PhPackage')]: () => import('phosphor-vue/dist/esm/components/PhPackage.vue.js'),
  [sanitizeKey('PhPaperclip')]: () => import('phosphor-vue/dist/esm/components/PhPaperclip.vue.js'),
  [sanitizeKey('PhPaperPlaneRight')]: () => import('phosphor-vue/dist/esm/components/PhPaperPlaneRight.vue.js'),
  [sanitizeKey('PhPencilSimple')]: () => import('phosphor-vue/dist/esm/components/PhPencilSimple.vue.js'),
  [sanitizeKey('PhPercent')]: () => import('phosphor-vue/dist/esm/components/PhPercent.vue.js'),
  [sanitizeKey('PhPlugs')]: () => import('phosphor-vue/dist/esm/components/PhPlugs.vue.js'),
  [sanitizeKey('PhPlugsConnected')]: () => import('phosphor-vue/dist/esm/components/PhPlugsConnected.vue.js'),
  [sanitizeKey('PhPlus')]: () => import('phosphor-vue/dist/esm/components/PhPlus.vue.js'),
  [sanitizeKey('PhPlusCircle')]: () => import('phosphor-vue/dist/esm/components/PhPlusCircle.vue.js'),
  [sanitizeKey('PhProhibit')]: () => import('phosphor-vue/dist/esm/components/PhProhibit.vue.js'),
  [sanitizeKey('PhQuestion')]: () => import('phosphor-vue/dist/esm/components/PhQuestion.vue.js'),
  [sanitizeKey('PhReceipt')]: () => import('phosphor-vue/dist/esm/components/PhReceipt.vue.js'),
  [sanitizeKey('PhSelectionForeground')]: () => import('phosphor-vue/dist/esm/components/PhSelectionForeground.vue.js'),
  [sanitizeKey('PhShoppingCart')]: () => import('phosphor-vue/dist/esm/components/PhShoppingCart.vue.js'),
  [sanitizeKey('PhSpeakerSimpleHigh')]: () => import('phosphor-vue/dist/esm/components/PhSpeakerSimpleHigh.vue.js'),
  [sanitizeKey('PhSpinnerGap')]: () => import('phosphor-vue/dist/esm/components/PhSpinnerGap.vue.js'),
  [sanitizeKey('PhSquare')]: () => import('phosphor-vue/dist/esm/components/PhSquare.vue.js'),
  [sanitizeKey('PhStarHalf')]: () => import('phosphor-vue/dist/esm/components/PhStarHalf.vue.js'),
  [sanitizeKey('PhStopCircle')]: () => import('phosphor-vue/dist/esm/components/PhStopCircle.vue.js'),
  [sanitizeKey('PhStorefront')]: () => import('phosphor-vue/dist/esm/components/PhStorefront.vue.js'),
  [sanitizeKey('PhTabs')]: () => import('phosphor-vue/dist/esm/components/PhTabs.vue.js'),
  [sanitizeKey('PhTicket')]: () => import('phosphor-vue/dist/esm/components/PhTicket.vue.js'),
  [sanitizeKey('PhTranslate')]: () => import('phosphor-vue/dist/esm/components/PhTranslate.vue.js'),
  [sanitizeKey('PhTrash')]: () => import('phosphor-vue/dist/esm/components/PhTrash.vue.js'),
  [sanitizeKey('PhTreeStructure')]: () => import('phosphor-vue/dist/esm/components/PhTreeStructure.vue.js'),
  [sanitizeKey('PhUpload')]: () => import('phosphor-vue/dist/esm/components/PhUpload.vue.js'),
  [sanitizeKey('PhUploadSimple')]: () => import('phosphor-vue/dist/esm/components/PhUploadSimple.vue.js'),
  [sanitizeKey('PhUser')]: () => import('phosphor-vue/dist/esm/components/PhUser.vue.js'),
  [sanitizeKey('PhUserCircle')]: () => import('phosphor-vue/dist/esm/components/PhUserCircle.vue.js'),
  [sanitizeKey('PhUsersThree')]: () => import('phosphor-vue/dist/esm/components/PhUsersThree.vue.js'),
  [sanitizeKey('PhWarning')]: () => import('phosphor-vue/dist/esm/components/PhWarning.vue.js'),
  [sanitizeKey('PhWarningCircle')]: () => import('phosphor-vue/dist/esm/components/PhWarningCircle.vue.js'),
  [sanitizeKey('PhWifiHigh')]: () => import('phosphor-vue/dist/esm/components/PhWifiHigh.vue.js'),
  [sanitizeKey('PhWifiLow')]: () => import('phosphor-vue/dist/esm/components/PhWifiLow.vue.js'),
  [sanitizeKey('PhWifiSlash')]: () => import('phosphor-vue/dist/esm/components/PhWifiSlash.vue.js'),
  [sanitizeKey('PhX')]: () => import('phosphor-vue/dist/esm/components/PhX.vue.js'),
  [sanitizeKey('PhXCircle')]: () => import('phosphor-vue/dist/esm/components/PhXCircle.vue.js'),
  [sanitizeKey('PhXSquare')]: () => import('phosphor-vue/dist/esm/components/PhXSquare.vue.js'),
};

export function iconIsInList (sanitizedIcon) {
  return Object.prototype.hasOwnProperty.call(phIcons, sanitizedIcon);
}

export type PhIcons = keyof typeof phIcons;
export type PhIconSizes = 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl'| '4xl'| '5xl'| '6xl' | number;

export const getPhIcon = async (icon: string) => {
  const sanitizedIcon = sanitizeKey(icon);
  if (!iconIsInList(sanitizedIcon)) {
    console.warn(`Icon '${icon}' is not added!`);
    return null;
  }

  try {
    return (await phIcons[sanitizedIcon]()).default;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const HLPhIconFallback = defineComponent({
  name: 'HLPropWatcher',
  props: {
    icon: {
      type: String as (PropType<PhIcons> | PropType<string>),
      required: true,
    },

    fallbackIcon: {
      type: String as (PropType<PhIcons> | PropType<string>),
      required: true,
    },
  },
  setup(props) {
    const mainIcon = computed(() => {
      const sanitizedIcon = sanitizeKey(props.icon);

      if (!iconIsInList(sanitizedIcon)) {
        return props.fallbackIcon;
      }

      return props.icon;
    });

    return {
      mainIcon,
    };
  },
  render() {
    const slotData = {
      icon: this.mainIcon,
    };

    return map((this.$slots?.default?.(slotData) ?? []), (slot) => h(slot));
  },
});

import {isNil} from 'lodash-es';
import VoucherPreviewDtoBase from './VoucherPreviewDto.base';

export default class VoucherPreviewDto extends VoucherPreviewDtoBase {
  get usageCountWithMax() {
    if (isNil(this.maxUsageCount)) {
      return `${this.usageCount}`;
    }

    return `${this.usageCount}/${this.maxUsageCount}`;
  }
}

import ArrayType, {ArrayTypeHelper} from '@api/posMiddlewareCore/types/ArrayType';
import StringType from '@api/posMiddlewareCore/types/StringType';
import {
  Entity as EntityBase,
  RealTypesOf,
  SerializedTypesOf,
} from '@designeo/apibundle-js';

export const UserDtoFields = {
  name: {
    type: new StringType(),
  },
  roles: {
    type: new ArrayType(new StringType()),
  },
};

export interface IUserDtoBaseData {
  name: StringType,
  roles: ArrayTypeHelper<StringType>,
}

export default class UserDtoBase<
  OverrideCtorData extends Record<string, any> = IUserDtoBaseData,
  OverrideData extends Record<string, any> = IUserDtoBaseData
> extends EntityBase<
  SerializedTypesOf<OverrideCtorData>,
  RealTypesOf<OverrideData>
  > {
  readonly $fields = UserDtoFields;
  static readonly $fields = UserDtoFields;
  readonly $validations = null;
  static readonly $validations = null;
  get name() {
    return this.$get('name');
  }
  set name(value) {
    this.$set('name', value);
  }
  get roles() {
    return this.$get('roles');
  }
  set roles(value) {
    this.$set('roles', value);
  }
}

import {AclKey} from '@api/posMiddlewareCore/acl/acl';
import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('./Grid/GridPage.vue'),
    meta: {
      aclKeyForAccess: AclKey.transactionList,
    },
  },
  {
    path: '/transactions/point-of-sale/:shopPosCode',
    name: 'transactions-point-of-sale',
    component: () => import('./Grid/GridPage.vue'),
    props: true,
    meta: {
      aclKeyForAccess: AclKey.transactionList,
    },
  },
  {
    path: '/transaction/:id',
    name: 'transaction',
    component: () => import('./Detail/DetailPage.vue'),
    props: true,
    redirect: {name: 'transaction-printouts'},
    children: [
      {
        path: 'printouts',
        name: 'transaction-printouts',
        component: () => import('./Detail/tabs/TabPrintouts.vue'),
      },
      {
        path: 'items',
        name: 'transaction-items',
        component: () => import('./Detail/tabs/TabItems.vue'),
      },
    ],
  },
] as RegisterRoutesOptions;

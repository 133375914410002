export default {
  cs: {
    transactionItem: {
      fields: {
        id: 'Ean / Interní číslo (batch)',
        description: 'Popis',
        quantity: 'Množství',
        valueAfterDiscounts: 'Hodnota',
      },
    },
  },
  sk: {
    transactionItem: {
      fields: {
        id: 'Ean / Interné číslo (batch)',
        description: 'Popis',
        quantity: 'Množstvo',
        valueAfterDiscounts: 'Hodnota',
      },
    },
  },
  en: {
    transactionItem: {
      fields: {
        id: 'Ean / Internal Number (batch)',
        description: 'Description',
        quantity: 'Quantity',
        valueAfterDiscounts: 'Value',
      },
    },
  },
  de: {
    transactionItem: {
      fields: {
        id: 'Ean / Interne Nummer (Batch)',
        description: 'Beschreibung',
        quantity: 'Menge',
        valueAfterDiscounts: 'Wert',
      },
    },
  },
};

/* eslint-disable max-len */
import StringType from '@api/posMiddlewareCore/types/StringType';
import {createEntity} from '@designeo/apibundle-js';
import {Feature} from '@middleware-ui/constants/feature';


export class FeatureFormEntity extends createEntity({
  data: {
    type: new StringType(),
  },
}) {
  get schema() {
    return {
      '$schema': 'http://json-schema.org/draft-07/schema',
      'version': '0.0.1',
      'type': 'array',
      'items': {
        'type': 'object',
        'uniqueItems': true,
        'discriminator': {
          'propertyName': 'name',
        },
        'required': [
          'name',
        ],
        'properties': {
          'name': {
            'type': 'string',
            'enum': [
              Feature.livePromotionDebugger,
              Feature.aiPromotionHelper,
              Feature.configurationEditor,
              Feature.aiChat,
            ],
          },
        },
        'oneOf': [
          {
            'type': 'object',
            'properties': {
              'name': {
                'const': Feature.livePromotionDebugger,
              },
              'parameters': {
                'type': 'object',
                'additionalProperties': false,
                'properties': {
                  'posDebugUrl': {
                    'type': 'string',
                  },
                },
                'required': [
                  'posDebugUrl',
                ],
              },
            },
            'required': [
              'name',
              'parameters',
            ],
          }, {
            'type': 'object',
            'properties': {
              'name': {
                'const': Feature.aiPromotionHelper,
              },
              'parameters': {
                'type': 'object',
                'additionalProperties': false,
                'properties': {
                  'chatUrl': {
                    'type': 'string',
                  },
                  'webhookUrl': {
                    'type': 'string',
                  },
                },
                'required': [
                  'chatUrl',
                  'webhookUrl',
                ],
              },
            },
            'required': [
              'name',
              'parameters',
            ],
          }, {
            'type': 'object',
            'properties': {
              'name': {
                'const': Feature.configurationEditor,
              },
            },
          }, {
            'type': 'object',
            'properties': {
              'name': {
                'const': Feature.aiChat,
              },
              'parameters': {
                'type': 'object',
                'additionalProperties': false,
                'properties': {
                  'agentName': {
                    'type': 'string',
                  },
                  'librechatUrl': {
                    'type': 'string',
                  },
                  'login': {
                    'type': 'string',
                  },
                  'password': {
                    'type': 'string',
                  },
                },
                'required': [
                  'agentName',
                  'librechatUrl',
                  'login',
                  'password',
                ],
              },
            },
            'required': [
              'name',
              'parameters',
            ],
          },
        ],
      },
    };
  }
  get app() {
    return 'middleware-ui';
  }
}

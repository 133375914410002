import ILinkObject from '@api/posMiddlewareCore/entity/ILinkObject';
import VoucherPreviewDto from '@api/posMiddlewareCore/entity/VoucherPreviewDto';
import ArrayType, {ArrayTypeHelper} from '@api/posMiddlewareCore/types/ArrayType';
import EntityType from '@api/posMiddlewareCore/types/EntityType';
import StringType from '@api/posMiddlewareCore/types/StringType';
import {
  Entity as EntityBase,
  RealTypesOf,
  SerializedTypesOf,
} from '@designeo/apibundle-js';

export const VoucherPreviewDtoIEnvelopeFields = {
  operationId: {
    type: new StringType(),
  },
  links: {
    type: new ArrayType(new EntityType(() => ILinkObject)),
  },
  data: {
    type: new EntityType(() => VoucherPreviewDto),
  },
};

export interface IVoucherPreviewDtoIEnvelopeBaseData {
  operationId: StringType,
  links: ArrayTypeHelper<EntityType<ILinkObject>>,
  data: EntityType<VoucherPreviewDto>,
}

export default class VoucherPreviewDtoIEnvelopeBase<
  OverrideCtorData extends Record<string, any> = IVoucherPreviewDtoIEnvelopeBaseData,
  OverrideData extends Record<string, any> = IVoucherPreviewDtoIEnvelopeBaseData
> extends EntityBase<
  SerializedTypesOf<OverrideCtorData>,
  RealTypesOf<OverrideData>
  > {
  readonly $fields = VoucherPreviewDtoIEnvelopeFields;
  static readonly $fields = VoucherPreviewDtoIEnvelopeFields;
  readonly $validations = null;
  static readonly $validations = null;
  get operationId() {
    return this.$get('operationId');
  }
  set operationId(value) {
    this.$set('operationId', value);
  }
  get links() {
    return this.$get('links');
  }
  set links(value) {
    this.$set('links', value);
  }
  get data() {
    return this.$get('data');
  }
  set data(value) {
    this.$set('data', value);
  }
}

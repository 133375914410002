import EnumField from '@api/posMiddlewareCore/entity/EnumField';
import ArrayType, {ArrayTypeHelper} from '@api/posMiddlewareCore/types/ArrayType';
import EntityType from '@api/posMiddlewareCore/types/EntityType';
import {
  Entity as EntityBase,
  RealTypesOf,
  SerializedTypesOf,
} from '@designeo/apibundle-js';

export const PosEnumsDtoFields = {
  updateTypes: {
    type: new ArrayType(new EntityType(() => EnumField)),
  },
  updateCondition: {
    type: new ArrayType(new EntityType(() => EnumField)),
  },
};

export interface IPosEnumsDtoBaseData {
  updateTypes: ArrayTypeHelper<EntityType<EnumField>>,
  updateCondition: ArrayTypeHelper<EntityType<EnumField>>,
}

export default class PosEnumsDtoBase<
  OverrideCtorData extends Record<string, any> = IPosEnumsDtoBaseData,
  OverrideData extends Record<string, any> = IPosEnumsDtoBaseData
> extends EntityBase<
  SerializedTypesOf<OverrideCtorData>,
  RealTypesOf<OverrideData>
  > {
  readonly $fields = PosEnumsDtoFields;
  static readonly $fields = PosEnumsDtoFields;
  readonly $validations = null;
  static readonly $validations = null;
  get updateTypes() {
    return this.$get('updateTypes');
  }
  set updateTypes(value) {
    this.$set('updateTypes', value);
  }
  get updateCondition() {
    return this.$get('updateCondition');
  }
  set updateCondition(value) {
    this.$set('updateCondition', value);
  }
}

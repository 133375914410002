import {Feature} from '@middleware-ui/constants/feature';
import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/chat',
    name: 'chat',
    component: () => import('./Chat/ChatPage.vue'),
    meta: {
      featureFlagForAccess: Feature.aiChat,
    },
  },
] as RegisterRoutesOptions;

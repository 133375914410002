import {Entity} from '@designeo/apibundle-js/src/Entity/base';
import {ComponentObjectPropsOptions, PropType} from 'vue';

export const gridCellProps = {
  value: {
    type: null as PropType<any>,
    required: true,
  },
  item: {
    type: Object as PropType<Entity<any, any>>,
    required: true,
  },
};

export type GridCellProps<
  T extends any = any,
  E extends Entity<any, any> = Entity<any, any>,
  O extends Record<string, unknown> = {},
> = ComponentObjectPropsOptions<{
  value: T,
  item: E,
} & O>;

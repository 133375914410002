import {AclMap} from '@api/posMiddlewareCore/acl/acl';
import apiV1IdentityMe from '@api/posMiddlewareCore/action/apiV1IdentityMe';
import {createContext} from '@designeo/js-helpers';
import LayoutDefault from '@middleware-ui/layouts/LayoutDefault.vue';
import {FeatureFormEntity} from '@middleware-ui/model/entity/FeatureFormEntity';
import {registerAppCoreModule} from '@middleware-ui/modules/AppCore';
import {registerCashierModule} from '@middleware-ui/modules/Cashier';
import {registerChatModule} from '@middleware-ui/modules/Chat';
import {registerConfigurationModule} from '@middleware-ui/modules/Configuration';
import {registerImportModule} from '@middleware-ui/modules/Import';
import {registerMiddlewareConfigurationModule} from '@middleware-ui/modules/MiddlewareConfiguration';
import {registerPointOfSaleModule} from '@middleware-ui/modules/PointOfSale';
import {registerPointOfSaleAttributeModule} from '@middleware-ui/modules/PointOfSaleAttribute';
import {registerPointOfSaleConfigurationModule} from '@middleware-ui/modules/PointOfSaleConfiguration';
import {registerPointOfSaleSynchronizationModule} from '@middleware-ui/modules/PointOfSaleSynchronization';
import {registerPointOfSaleTransactionModule} from '@middleware-ui/modules/PointOfSaleTransaction';
import {registerPointOfSaleVersionModule} from '@middleware-ui/modules/PointOfSaleVersion';
import {registerPromotionModule} from '@middleware-ui/modules/Promotion';
import {registerPromotionListModule} from '@middleware-ui/modules/PromotionList';
import {registerShopModule} from '@middleware-ui/modules/Shop';
import {registerShopConfigurationModule} from '@middleware-ui/modules/ShopConfiguration';
import {registerTransactionModule} from '@middleware-ui/modules/Transaction';
import {registerUpdateStatusModule} from '@middleware-ui/modules/UpdateStatus';
import {registerVoucherModule} from '@middleware-ui/modules/Voucher';
import {registerVoucherEmissionModule} from '@middleware-ui/modules/VoucherEmission';
import {registerVoucherEventModule} from '@middleware-ui/modules/VoucherEvent';
import App from '@ui/layouts/App.vue';
import {registerAuthModule} from '@ui/modules/Auth';
import {registerCoreModule} from '@ui/modules/Core';
import {registerCrashDetection} from '@ui/plugins/crash';
import {registerVueI18n} from '@ui/plugins/i18n';
import {registerModuleSystem} from '@ui/plugins/modules';
import {registerRouter} from '@ui/plugins/router';
import {registerSentry} from '@ui/plugins/sentry';
import {registerSSRState} from '@ui/plugins/ssrState';
import {registerStartup} from '@ui/plugins/startup';
import {registerTestHelpers} from '@ui/plugins/testHelpers';
import {registerArticleListModule} from './modules/ArticleList';
import {registerCashierRoleModule} from './modules/CashierRole';
import {registerDashboardModule} from './modules/Dashboard';
import {registerReportDefinitionModule} from './modules/ReportDefinition';
import {registerTransactionItemModule} from './modules/TransactionItem';
import {registerTransactionPrintoutModule} from './modules/TransactionPrintout';


interface createContextOptions {
  createApp: import('vue').CreateAppFunction<any>,
  ssrState: any,
}

export default async ({createApp, ssrState}: createContextOptions) => {
  const appId = {};

  const app = createApp(App, {
    appId,
  });

  const ctx = createContext({
    app,
    appId,
    isSplashScreen: false,
    preloadHooks: [],
    defaultLayout: LayoutDefault,
  })
    .register(registerCrashDetection)
    .register(registerStartup)
    .register(registerTestHelpers)
    .register(registerModuleSystem)
    .register(registerAuthModule({apiIdentifyMe: apiV1IdentityMe, aclMap: AclMap}))
    .register(registerCoreModule({FeaturesCtor: FeatureFormEntity}))
    .register(registerAppCoreModule)
    .register(registerArticleListModule)
    .register(registerCashierModule)
    .register(registerCashierRoleModule)
    .register(registerChatModule)
    .register(registerConfigurationModule)
    .register(registerDashboardModule)
    .register(registerImportModule)
    .register(registerMiddlewareConfigurationModule)
    .register(registerPointOfSaleModule)
    .register(registerPointOfSaleAttributeModule)
    .register(registerPointOfSaleConfigurationModule)
    .register(registerPointOfSaleSynchronizationModule)
    .register(registerPointOfSaleVersionModule)
    .register(registerPointOfSaleTransactionModule)
    .register(registerPromotionModule)
    .register(registerPromotionListModule)
    .register(registerReportDefinitionModule)
    .register(registerShopModule)
    .register(registerShopConfigurationModule)
    .register(registerUpdateStatusModule)
    .register(registerVoucherModule)
    .register(registerVoucherEmissionModule)
    .register(registerVoucherEventModule)
    .register(registerTransactionModule)
    .register(registerTransactionItemModule)
    .register(registerTransactionPrintoutModule)
    .register(registerVueI18n)
    .register(registerRouter)
    .register(registerSSRState(ssrState))
    .register(registerSentry)
  ;

  const resolvedContext = await ctx.resolve();

  app.config.globalProperties.$context = resolvedContext;

  return resolvedContext;
};

export default {
  cs: {
    transaction: {
      detail: {
        title: 'Transakce - {documentNumber}',
        tabs: {
          items: {
            title: 'Položky',
            bypassedType: 'Pro tento typ transakce není možné zobrazit položky.',
          },
          printouts: {
            title: 'Výtisky',
          },
        },
      },
      grid: {
        title: 'Transakce',
        filters: {
          shopPosCode: 'Prodejna a pokladna',
          documentDateFrom: 'Datum od',
          documentDateTo: 'Datum do',
          cashierSearch: 'Pokladní',
          documentNumber: 'Číslo dokladu',
          documentType: 'Typ dokladu',
          customerNumber: 'Číslo zákazníka',
          documentTypeEnum: {
            allOptions: 'Všechny typy',
          },
          internalNumber: 'Položka',
        },
        advancedFilters: {
          title: 'Filtrovat transakce',
          search: 'Vyhledat',
          placeholders: {
            documentType: 'Vyberte typ dokladu',
            shopPosCode: 'Zadejte prodejnu/pokladnu',
            documentDateFrom: 'Zadejte datum od',
            documentDateTo: 'Zadejte datum do',
            cashierSearch: 'Zadejte jméno nebo osobní číslo pokladní',
            documentNumber: 'Zadejte číslo dokladu',
            customerNumber: 'Zadejte číslo zákazníka',
            internalNumber: 'Zadejte číslo položky',
          },
        },
        cells: {
          description: {
            promotion: 'Promoce',
          },
        },
        actions: {
          showPrint: {
            label: 'Zobrazit výtisk',
          },
        },
      },
      fields: {
        documentDate: 'Datum a čas dokladu',
        documentNumber: 'Číslo dokladu',
        totalPrice: 'Hodnota',
        shopPosCode: 'Pokladna',
        cashierNameAndPersonalNumber: 'Pokladní',
        customerNumber: 'Číslo zákazníka',
        documentType: 'Typ dokladu',
        customerNumberOrCardNumber: 'Zákazník (číslo nebo karta)',
      },
      printoutDetail: {
        title: 'Výtisk',
        notFound: 'Žádný výtisk nenalezen',
        print: 'Tisk',
      },
    },
  },
  sk: {
    transaction: {
      detail: {
        title: 'Transakcia - {documentNumber}',
        tabs: {
          items: {
            title: 'Položky',
            bypassedType: 'Pre tento typ transakcie nie je možné zobraziť položky.',
          },
          printouts: {
            title: 'Výtlačky',
          },
        },
      },
      grid: {
        title: 'Transakcie',
        filters: {
          shopPosCode: 'Predajňa a pokladňa',
          documentDateFrom: 'Dátum od',
          documentDateTo: 'Dátum do',
          cashierSearch: 'Pokladník',
          documentNumber: 'Číslo dokladu',
          documentType: 'Typ dokladu',
          customerNumber: 'Číslo zákazníka',
          documentTypeEnum: {
            allOptions: 'Všetky typy',
          },
          internalNumber: 'Položka',
        },
        advancedFilters: {
          title: 'Filtrovať transakcie',
          search: 'Vyhľadať',
          placeholders: {
            documentType: 'Vyberte typ dokladu',
            shopPosCode: 'Zadejte predajňu/pokladňu',
            documentDateFrom: 'Zadejte dátum od',
            documentDateTo: 'Zadejte dátum do',
            cashierSearch: 'Zadejte meno alebo osobné číslo pokladníka',
            documentNumber: 'Zadejte číslo dokladu',
            customerNumber: 'Zadejte číslo zákazníka',
            internalNumber: 'Zadejte číslo položky',
          },
        },
        cells: {
          description: {
            promotion: 'Promócia',
          },
        },
        actions: {
          showPrint: {
            label: 'Zobraziť výtlačok',
          },
        },
      },
      fields: {
        documentDate: 'Dátum a čas dokladu',
        documentNumber: 'Číslo dokladu',
        totalPrice: 'Hodnota',
        shopPosCode: 'Pokladňa',
        cashierNameAndPersonalNumber: 'Pokladník',
        customerNumber: 'Číslo zákazníka',
        documentType: 'Typ dokladu',
        customerNumberOrCardNumber: 'Zákazník (číslo alebo karta)',
      },
      printoutDetail: {
        title: 'Výtlačok',
        notFound: 'Žiadny výtlačok nenájdený',
        print: 'Tlač',
      },
    },
  },
  en: {
    transaction: {
      detail: {
        title: 'Transaction - {documentNumber}',
        tabs: {
          items: {
            title: 'Items',
            bypassedType: 'Items cannot be displayed for this type of transaction.',
          },
          printouts: {
            title: 'Printouts',
          },
        },
      },
      grid: {
        title: 'Transactions',
        filters: {
          shopPosCode: 'Store and Register',
          documentDateFrom: 'Date from',
          documentDateTo: 'Date to',
          cashierSearch: 'Cashier',
          documentNumber: 'Document Number',
          documentType: 'Document Type',
          customerNumber: 'Customer number',
          documentTypeEnum: {
            allOptions: 'All types',
          },
          internalNumber: 'Item',
        },
        advancedFilters: {
          title: 'Filter transactions',
          search: 'Search',
          placeholders: {
            documentType: 'Select document type',
            shopPosCode: 'Enter store/register',
            documentDateFrom: 'Enter date from',
            documentDateTo: 'Enter date to',
            cashierSearch: 'Enter cashier name or personal number',
            documentNumber: 'Enter document number',
            customerNumber: 'Enter customer number',
            internalNumber: 'Enter item number',
          },
        },
        cells: {
          description: {
            promotion: 'Promotion',
          },
        },
        actions: {
          showPrint: {
            label: 'Show printout',
          },
        },
      },
      fields: {
        documentDate: 'Document date and time',
        documentNumber: 'Document number',
        totalPrice: 'Value',
        shopPosCode: 'Register',
        cashierNameAndPersonalNumber: 'Cashier',
        customerNumber: 'Customer number',
        documentType: 'Document type',
        customerNumberOrCardNumber: 'Customer (number or card)',
      },
      printoutDetail: {
        title: 'Printout',
        notFound: 'No printout found',
        print: 'Print',
      },
    },
  },
  de: {
    transaction: {
      detail: {
        title: 'Transaktion - {documentNumber}',
        tabs: {
          items: {
            title: 'Artikel',
            bypassedType: 'Für diesen Transaktionstyp können keine Artikel angezeigt werden.',
          },
          printouts: {
            title: 'Ausdrucke',
          },
        },
      },
      grid: {
        title: 'Transaktionen',
        filters: {
          shopPosCode: 'Geschäft und Kasse',
          documentDateFrom: 'Datum von',
          documentDateTo: 'Datum bis',
          cashierSearch: 'Kassierer',
          documentNumber: 'Belegnummer',
          documentType: 'Belegtyp',
          customerNumber: 'Kundennummer',
          documentTypeEnum: {
            allOptions: 'Alle Typen',
          },
          internalNumber: 'Artikel',
        },
        advancedFilters: {
          title: 'Transaktionen filtern',
          search: 'Suchen',
          placeholders: {
            documentType: 'Belegtyp auswählen',
            shopPosCode: 'Geschäft/Kasse eingeben',
            documentDateFrom: 'Datum von eingeben',
            documentDateTo: 'Datum bis eingeben',
            cashierSearch: 'Name oder Personalnummer des Kassierers eingeben',
            documentNumber: 'Belegnummer eingeben',
            customerNumber: 'Kundennummer eingeben',
            internalNumber: 'Artikelnummer eingeben',
          },
        },
        cells: {
          description: {
            promotion: 'Aktion',
          },
        },
        actions: {
          showPrint: {
            label: 'Ausdruck anzeigen',
          },
        },
      },
      fields: {
        documentDate: 'Belegdatum und -uhrzeit',
        documentNumber: 'Belegnummer',
        totalPrice: 'Wert',
        shopPosCode: 'Kasse',
        cashierNameAndPersonalNumber: 'Kassierer',
        customerNumber: 'Kundennummer',
        documentType: 'Belegtyp',
        customerNumberOrCardNumber: 'Kunde (Nummer oder Karte)',
      },
      printoutDetail: {
        title: 'Ausdruck',
        notFound: 'Kein Ausdruck gefunden',
        print: 'Drucken',
      },
    },
  },
};

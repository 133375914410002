import StringType from '@api/posMiddlewareCore/types/StringType';
import {
  EntityScalar as EntityBase,
  SerializedTypeOf,
  RealTypeOf,
} from '@designeo/apibundle-js';

export const VoucherStateFields = {
  type: {
    type: new StringType(),
  },
};

export type TVoucherStateBaseData = StringType;

export default class VoucherStateBase extends EntityBase<
SerializedTypeOf<TVoucherStateBaseData>,
{type: RealTypeOf<TVoucherStateBaseData>}
> {
  readonly $fields = VoucherStateFields;
  static readonly $fields = VoucherStateFields;
  readonly $validations = null;
  static readonly $validations = null;
  get value() {
    return this.$get('type');
  }
  set value(val) {
    this.$set('type', val);
  }
}

import VoucherPreviewDtoIEnvelope from '@api/posMiddlewareCore/entity/VoucherPreviewDtoIEnvelope';
import ArrayType, {ArrayTypeHelper} from '@api/posMiddlewareCore/types/ArrayType';
import EntityType from '@api/posMiddlewareCore/types/EntityType';
import NumberType from '@api/posMiddlewareCore/types/NumberType';
import {
  Entity as EntityBase,
  RealTypesOf,
  SerializedTypesOf,
} from '@designeo/apibundle-js';

export const VoucherPreviewDtoIEnvelopeIPagingObjectFields = {
  data: {
    type: new ArrayType(new EntityType(() => VoucherPreviewDtoIEnvelope)),
  },
  limit: {
    type: new NumberType(),
  },
  offset: {
    type: new NumberType(),
  },
  total: {
    type: new NumberType(),
  },
};

export interface IVoucherPreviewDtoIEnvelopeIPagingObjectBaseData {
  data: ArrayTypeHelper<EntityType<VoucherPreviewDtoIEnvelope>>,
  limit: NumberType,
  offset: NumberType,
  total: NumberType,
}

export default class VoucherPreviewDtoIEnvelopeIPagingObjectBase<
  OverrideCtorData extends Record<string, any> = IVoucherPreviewDtoIEnvelopeIPagingObjectBaseData,
  OverrideData extends Record<string, any> = IVoucherPreviewDtoIEnvelopeIPagingObjectBaseData
> extends EntityBase<
  SerializedTypesOf<OverrideCtorData>,
  RealTypesOf<OverrideData>
  > {
  readonly $fields = VoucherPreviewDtoIEnvelopeIPagingObjectFields;
  static readonly $fields = VoucherPreviewDtoIEnvelopeIPagingObjectFields;
  readonly $validations = null;
  static readonly $validations = null;
  get data() {
    return this.$get('data');
  }
  set data(value) {
    this.$set('data', value);
  }
  get limit() {
    return this.$get('limit');
  }
  set limit(value) {
    this.$set('limit', value);
  }
  get offset() {
    return this.$get('offset');
  }
  set offset(value) {
    this.$set('offset', value);
  }
  get total() {
    return this.$get('total');
  }
  set total(value) {
    this.$set('total', value);
  }
}

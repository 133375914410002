import ObjectType from '@api/posMiddlewareCore/types/ObjectType';
import StringType from '@api/posMiddlewareCore/types/StringType';
import {
  Entity as EntityBase,
  RealTypesOf,
  SerializedTypesOf,
} from '@designeo/apibundle-js';

export const ErrorFields = {
  code: {
    type: new StringType(),
  },
  key: {
    type: new StringType(),
  },
  title: {
    type: new StringType(),
  },
  instructions: {
    type: new StringType(),
  },
  property: {
    type: new StringType(),
  },
  parameters: {
    type: new ObjectType(),
  },
};

export interface IErrorBaseData {
  code: StringType,
  key: StringType,
  title: StringType,
  instructions: StringType,
  property: StringType,
  parameters: ObjectType,
}

export default class ErrorBase<
  OverrideCtorData extends Record<string, any> = IErrorBaseData,
  OverrideData extends Record<string, any> = IErrorBaseData
> extends EntityBase<
  SerializedTypesOf<OverrideCtorData>,
  RealTypesOf<OverrideData>
  > {
  readonly $fields = ErrorFields;
  static readonly $fields = ErrorFields;
  readonly $validations = null;
  static readonly $validations = null;
  get code() {
    return this.$get('code');
  }
  set code(value) {
    this.$set('code', value);
  }
  get key() {
    return this.$get('key');
  }
  set key(value) {
    this.$set('key', value);
  }
  get title() {
    return this.$get('title');
  }
  set title(value) {
    this.$set('title', value);
  }
  get instructions() {
    return this.$get('instructions');
  }
  set instructions(value) {
    this.$set('instructions', value);
  }
  get property() {
    return this.$get('property');
  }
  set property(value) {
    this.$set('property', value);
  }
  get parameters() {
    return this.$get('parameters');
  }
  set parameters(value) {
    this.$set('parameters', value);
  }
}

/* eslint-disable vue/one-component-per-file */
import {FilterType} from '@designeo/apibundle-js/src/Types/grid';
import {Filter as FilterCls, FilterOperation} from '@designeo/vue-grid/src/Model/Filter';
import {HLApiFetchSelectOptions} from '@ui/components/headless/ApiFetch';
import {
  isArray,
  isEmpty,
  isNil,
  reject,
} from 'lodash-es';
import {DateTime} from 'luxon';
import {
  Component,
  defineComponent,
  h,
  inject,
  provide,
  ref,
  Ref,
} from 'vue';
import * as yup from 'yup';

export const getFilterDefaultStringValidator = (minLength = 2) => yup.string().when(
  (value, schema) => (value ?? '').length === 0 ? schema : schema.min(minLength),
);

export const getFilterEmptyStringValidator = () => yup.string().nullable();

export const getFilterEmptyArrayValidator = () => yup.array().nullable();

export const HLFilterWrapper = (
  WrappedComponent,
  {validator = getFilterDefaultStringValidator()}: {validator?: yup.AnySchema} = {},
) => defineComponent({
  name: 'HLFilterWrapper',
  props: {
    modelValue: {
      type: null,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  render() {
    return h(WrappedComponent, {
      'modelValue': this.modelValue,
      'onUpdate:modelValue': (value) => {
        if (!validator) {
          this.$emit('update:modelValue', value);
          return;
        }

        try {
          yup.object({value: validator}).validateSync({value});

          this.$emit('update:modelValue', value);
        } catch (e) {
          return;
        }
      },
    });
  },
});

export const HLFilterDateWrapper = (
  WrappedComponent,
  {
    validator = getFilterEmptyStringValidator(),
  }: {
    validator?: yup.AnySchema,
  } = {},
) => defineComponent({
  name: 'HLFilterWrapper',
  props: {
    modelValue: {
      type: null,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  render() {
    return h(WrappedComponent, {
      'modelValue': (() => {
        const val = isArray(this.modelValue) ? this.modelValue[0] : this.modelValue;

        if (isNil(val)) {
          return null;
        }

        return new Date(val);
      })(),
      'onUpdate:modelValue': (value) => {
        value = value ?? null;

        if (!validator) {
          this.$emit('update:modelValue', value);
          return;
        }

        try {
          yup.object({value: validator}).validateSync({value});

          this.$emit('update:modelValue', value);
        } catch (e) {
          return;
        }
      },
    });
  },
});

export const HLFilterArrayWrapper = (
  WrappedComponent,
) => defineComponent({
  name: 'HLFilterWrapper',
  props: {
    modelValue: {
      type: null,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  render() {
    return h(WrappedComponent, {
      'modelValue': this.modelValue,
      'onUpdate:modelValue': (value) => {
        if (isEmpty(value)) {
          value = null;
        }

        this.$emit('update:modelValue', value);
      },
    });
  },
});

export const HLApiSelectFilterWrapper = (
  WrappedComponent,
  fetchFn,
  {validator = getFilterEmptyStringValidator()}:
  {
    validator?: yup.AnySchema,
  } = {},
) => defineComponent({
  name: 'HLApiSelectFilterWrapper',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: null,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  render() {
    // @ts-ignore
    return h(HLApiFetchSelectOptions, {
      initialFetch: !!this.modelValue,
      fetch: fetchFn,
    }, ({ensureData, options, isLoading, sortBy}) => {
      const modelValue = this.modelValue;
      const onOpened = () => ensureData();
      const onUpdateModelValue = (value) => {
        value = isEmpty(value) ? null : value;

        if (!validator) {
          this.$emit('update:modelValue', value);
          return;
        }

        try {
          yup.object({value: validator}).validateSync({value});
          this.$emit('update:modelValue', value);
        } catch (e) {
          return;
        }
      };

      const readonly = isLoading;

      return h(WrappedComponent, {
        'modelValue': modelValue,
        'onOpened': onOpened,
        'onUpdate:modelValue': onUpdateModelValue,
        'options': options,
        'sortBy': sortBy,
        'readonly': readonly,
        ...this.$attrs,
      });
    });
  },
});

export const HLFilterBooleanWrapper = (
  WrappedComponent,
  {
    validator = getFilterEmptyStringValidator(),
  }: {
    validator?: yup.AnySchema,
  } = {},
) => defineComponent({
  name: 'HLFilterBooleanWrapper',
  props: {
    modelValue: {
      type: null,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  render() {
    return h(WrappedComponent, {
      'modelValue': this.modelValue,
      'onUpdate:modelValue': (value) => {
        value = value ?? null;

        if (!validator) {
          this.$emit('update:modelValue', value);
          return;
        }

        try {
          yup.object({value: validator}).validateSync({value});

          this.$emit('update:modelValue', value);
        } catch (e) {
          return;
        }
      },
    });
  },
});

const GridAdvancedFiltersContext = 'GridAdvancedFiltersContext';

export interface IGridAdvancedFilters {
  title: string,
  component: Component,
}

interface GridAdvancedFiltersApi extends IGridAdvancedFilters {
  opened: Ref<boolean>,
  areDefined: boolean,
  formIsValid: Ref<boolean>,
}

export const injectGridAdvancedFiltersContext = () => {
  return <GridAdvancedFiltersApi>inject(GridAdvancedFiltersContext);
};

export const provideGridAdvancedFiltersContext = ({
  component,
  title,
}: {
  title?: string,
  component?: Component,
} = {}) => {
  const opened = ref(false);
  const formIsValid = ref(true);

  const context: GridAdvancedFiltersApi = {
    title,
    component,
    opened,
    formIsValid,
    get areDefined() {
      return !!this.component;
    },
  };

  provide(GridAdvancedFiltersContext, context);
};

export function formatDateFilterValue(value: Date, {edge = null}: {edge?: 'bottom' | 'top'} = {}) {
  if (!value) {
    return value;
  }

  if (!edge) {
    return value.toISOString();
  }

  if (edge === 'bottom') {
    return DateTime.fromJSDate(value).startOf('day')
      .toJSDate()
      .toISOString();
  }

  return DateTime.fromJSDate(value).endOf('day')
    .toJSDate()
    .toISOString();
}

export function parseDateFilterValue(value: string, {edge = null}: {edge?: 'bottom' | 'top'} = {}) {
  if (!value) {
    return value;
  }

  if (!edge) {
    return value;
  }

  return DateTime.fromJSDate(new Date(value)).startOf('day')
    .toJSDate()
    .toISOString();
}

export function createFilterFromValue(
  val,
  id,
  operation: FilterOperation | string,
  {
    type,
    key = id,
  }: {
    type?: FilterType,
    key?: string,
  } = {},
) {
  val = val ?? null;

  if (isArray(val) && !(reject(val, isNil)).length) {
    return {
      key,
      filter: null,
    };
  }

  if (val === null || val === '') {
    return {
      key,
      filter: null,
    };
  }

  return {
    key,
    // @ts-ignore
    filter: new FilterCls(id, operation, val, type),
  };
}

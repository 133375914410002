export default {
  cs: {
    transactionPrintout: {
      fields: {
        index: 'Pořadí',
        printoutHtml: 'HTML výstup',
        outputType: 'Typ výstupu',
        isPrimaryOutput: 'Primární výstup',
        target: 'Cíl',
        templateCode: 'Kód šablony',
      },
    },
  },
  sk: {
    transactionPrintout: {
      fields: {
        index: 'Poradie',
        printoutHtml: 'HTML výstup',
        outputType: 'Typ výstupu',
        isPrimaryOutput: 'Primárny výstup',
        target: 'Cieľ',
        templateCode: 'Kód šablóny',
      },
    },
  },
  en: {
    transactionPrintout: {
      fields: {
        index: 'Order',
        printoutHtml: 'HTML Output',
        outputType: 'Output Type',
        isPrimaryOutput: 'Primary Output',
        target: 'Target',
        templateCode: 'Template Code',
      },
    },
  },
  de: {
    transactionPrintout: {
      fields: {
        index: 'Reihenfolge',
        printoutHtml: 'HTML-Ausgabe',
        outputType: 'Ausgabetyp',
        isPrimaryOutput: 'Primäre Ausgabe',
        target: 'Ziel',
        templateCode: 'Vorlagencode',
      },
    },
  },
};

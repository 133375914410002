import StringType from '@api/posMiddlewareCore/types/StringType';
import {
  Entity as EntityBase,
  RealTypesOf,
  SerializedTypesOf,
} from '@designeo/apibundle-js';

export const VoucherEmissionSmallDtoFields = {
  name: {
    type: new StringType(),
  },
  code: {
    type: new StringType(),
  },
};

export interface IVoucherEmissionSmallDtoBaseData {
  name: StringType,
  code: StringType,
}

export default class VoucherEmissionSmallDtoBase<
  OverrideCtorData extends Record<string, any> = IVoucherEmissionSmallDtoBaseData,
  OverrideData extends Record<string, any> = IVoucherEmissionSmallDtoBaseData
> extends EntityBase<
  SerializedTypesOf<OverrideCtorData>,
  RealTypesOf<OverrideData>
  > {
  readonly $fields = VoucherEmissionSmallDtoFields;
  static readonly $fields = VoucherEmissionSmallDtoFields;
  readonly $validations = null;
  static readonly $validations = null;
  get name() {
    return this.$get('name');
  }
  set name(value) {
    this.$set('name', value);
  }
  get code() {
    return this.$get('code');
  }
  set code(value) {
    this.$set('code', value);
  }
}

import Error from '@api/posMiddlewareCore/entity/Error';
import ArrayType, {ArrayTypeHelper} from '@api/posMiddlewareCore/types/ArrayType';
import BaseType from '@api/posMiddlewareCore/types/BaseType';
import EntityType from '@api/posMiddlewareCore/types/EntityType';
import StringType from '@api/posMiddlewareCore/types/StringType';
import {
  Entity as EntityBase,
  RealTypesOf,
  SerializedTypesOf,
} from '@designeo/apibundle-js';

export const ErrorResponseFields = {
  identifier: {
    type: new StringType(),
  },
  errors: {
    type: new ArrayType(new EntityType(() => Error)),
  },
  additionalProperties: {
    type: new BaseType(),
  },
};

export interface IErrorResponseBaseData {
  identifier: StringType,
  errors: ArrayTypeHelper<EntityType<Error>>,
  additionalProperties: BaseType,
}

export default class ErrorResponseBase<
  OverrideCtorData extends Record<string, any> = IErrorResponseBaseData,
  OverrideData extends Record<string, any> = IErrorResponseBaseData
> extends EntityBase<
  SerializedTypesOf<OverrideCtorData>,
  RealTypesOf<OverrideData>
  > {
  readonly $fields = ErrorResponseFields;
  static readonly $fields = ErrorResponseFields;
  readonly $validations = null;
  static readonly $validations = null;
  get identifier() {
    return this.$get('identifier');
  }
  set identifier(value) {
    this.$set('identifier', value);
  }
  get errors() {
    return this.$get('errors');
  }
  set errors(value) {
    this.$set('errors', value);
  }
  get additionalProperties() {
    return this.$get('additionalProperties');
  }
  set additionalProperties(value) {
    this.$set('additionalProperties', value);
  }
}
